import { _global } from '../config/config';
import requestAPI from '../utils/requestAPI';
import { getStringByLanguage } from '../utils/utils';
import { PaginationProps, ResponseProps, prepareRequestParams } from './utils';

export enum TimeRange {
  Today = 'Today',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
  Custom = 'Custom',
}

export enum StreamType {
  Total = 'Total',
  Shared = 'Shared',
}

export enum StreamTag {
  Production = 'Production',
  Consumption = 'Consumption',
}

export interface StreamBasicInfo {
  '@timestamp': string;
  community: string;
  participant: string;
  device: string;
  type: StreamType;
  tag: StreamTag;
  value:
    | {
        min: number;
        max: number;
        sum: number;
        value_count: number;
      }
    | number;
  unit: string;
}

export type StreamInfo = StreamBasicInfo & Pick<StreamHits, '_id' | '_index'>;

export interface AggregatedDict {
  [key: number]: {
    value: number;
    unit: string;
  };
}

export interface StreamProduct {
  total: number;
  shared: number;
}

export interface StreamProductWithParticipants extends StreamProduct {
  totalMembers: {
    [id: string]: {
      value: number;
      unit: string;
    };
  };
  sharedMembers: {
    [id: string]: {
      value: number;
      unit: string;
    };
  };
}

export interface StreamData extends StreamProductWithParticipants {
  unit: string;
}

export interface StreamValue {
  [unit: string]: StreamProductWithParticipants;
}

export interface StreamProduceAndConsume {
  production: StreamValue;
  consumption: StreamValue;
}

export interface StreamByTimeStamp {
  [timestamp: string | number]: StreamProduceAndConsume;
}

export interface StreamReqRangeProps {
  range?: TimeRange;
  startDate?: Date;
  endDate?: Date;
  deviceId?: number;
}

export interface AggregatedValue {
  key: string;
  doc_count: number;
  total: {
    value: number;
  };
}

export interface GroupByUnit {
  key: string;
  doc_count: number;
  unit: {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: AggregatedValue[];
  };
}

export interface GroupByDevice<KeyProps = StreamType> {
  key: KeyProps;
  doc_count: number;
  device: {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: GroupByUnit[];
  };
}

export interface GroupByParticipant {
  key: StreamType;
  doc_count: number;
  participant: {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: GroupByUnit[];
  };
}

export interface GroupByParticipantAndDevice {
  key: StreamType;
  doc_count: number;
  participant: {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: GroupByDevice<string>[];
  };
}

export interface GroupByType<SubGroup> {
  key: StreamTag;
  doc_count: number;
  type: {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: SubGroup[];
  };
}

export interface GroupByTagByTimeline<SubGroup> {
  key: {
    timeline: number;
  };
  doc_count: number;
  tag: {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: GroupByType<SubGroup>[];
  };
}

export interface GroupByTagByDevice {
  key: {
    device: number;
  };
  doc_count: number;
  tag: {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: GroupByType<GroupByUnit>[];
  };
}

export interface AggregatedByTimeline<SubGroup> {
  after_key: {
    timeline: number;
  };
  buckets: GroupByTagByTimeline<SubGroup>[];
}

export interface AggregatedByDevice {
  after_key: {
    device: string;
  };
  buckets: GroupByTagByDevice[];
}

export interface AggregatedDocuments<T> {
  documents: T;
}

export interface AggregatedRequestProps {
  community_id?: number | null;
  participant_id?: number | null;
  interval?: '15m' | '1d' | '1M';
  groupBy?: 'participant' | 'device' | 'both';
  time_zone?: string;
  deviceId?: number | null;
}

export interface HitsRequestProps
  extends Omit<AggregatedRequestProps, 'interval'> {
  device_id: number | null;
}

export interface StreamHits {
  _id: string;
  _index: string;
  _score: number;
  _source: StreamBasicInfo;
}

export type StreamResponseFormat = [
  (
    | StreamHits[]
    | AggregatedByTimeline<GroupByParticipant>['buckets']
    | AggregatedByTimeline<GroupByDevice>['buckets']
    | AggregatedByTimeline<GroupByParticipantAndDevice>['buckets']
    | AggregatedByDevice['buckets']
  ),
  number,
];

export const isHits = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documents: any,
): documents is StreamHits[] => {
  if (!Array.isArray(documents)) {
    documents = [documents];
  }

  if (!documents.length || documents[0]._index) {
    return true;
  }

  return false;
};

export const isGroupedByParticipant = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documents: any,
): documents is AggregatedByTimeline<GroupByParticipant>['buckets'] => {
  if (!Array.isArray(documents)) {
    documents = [documents];
  }

  if (!documents.length) {
    return true;
  }

  if (documents[0].key?.timeline && documents[0].tag) {
    const tag = documents[0].tag;
    if (!tag.buckets) {
      return false;
    }
    if (!Array.isArray(tag.buckets)) {
      return false;
    }
    if (!tag.buckets.length) {
      return true;
    }

    const type = tag.buckets[0].type;
    if (type) {
      if (!type.buckets) {
        return false;
      }
      if (!Array.isArray(type.buckets)) {
        return false;
      }
      if (!type.buckets.length) {
        return true;
      }

      const participant = type.buckets[0].participant;
      if (participant) {
        if (!participant.buckets) {
          return false;
        }
        if (!Array.isArray(participant.buckets)) {
          return false;
        }
        if (!participant.buckets.length) {
          return true;
        }

        const unit = participant.buckets[0].unit;
        if (unit) {
          return true;
        }
      }
    }
  }

  return false;
};

export const isGroupedByDevice = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documents: any,
): documents is AggregatedByTimeline<GroupByDevice>['buckets'] => {
  if (!Array.isArray(documents)) {
    documents = [documents];
  }

  if (!documents.length) {
    return true;
  }

  if (documents[0].key?.timeline && documents[0].tag) {
    const tag = documents[0].tag;
    if (!tag.buckets) {
      return false;
    }
    if (!Array.isArray(tag.buckets)) {
      return false;
    }
    if (!tag.buckets.length) {
      return true;
    }

    const type = tag.buckets[0].type;
    if (type) {
      if (!type.buckets) {
        return false;
      }
      if (!Array.isArray(type.buckets)) {
        return false;
      }
      if (!type.buckets.length) {
        return true;
      }

      const device = type.buckets[0].device;
      if (device) {
        if (!device.buckets) {
          return false;
        }
        if (!Array.isArray(device.buckets)) {
          return false;
        }
        if (!device.buckets.length) {
          return true;
        }

        const unit = device.buckets[0].unit;
        if (unit) {
          return true;
        }
      }
    }
  }

  return false;
};

export const isGroupedByParticipantAndDevice = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documents: any,
): documents is AggregatedByTimeline<GroupByParticipantAndDevice>['buckets'] => {
  if (!Array.isArray(documents)) {
    documents = [documents];
  }

  if (!documents.length) {
    return true;
  }

  if (documents[0].key?.timeline && documents[0].tag) {
    const tag = documents[0].tag;
    if (!tag.buckets) {
      return false;
    }
    if (!Array.isArray(tag.buckets)) {
      return false;
    }
    if (!tag.buckets.length) {
      return true;
    }

    const type = tag.buckets[0].type;
    if (type) {
      if (!type.buckets) {
        return false;
      }
      if (!Array.isArray(type.buckets)) {
        return false;
      }
      if (!type.buckets.length) {
        return true;
      }

      const participant = type.buckets[0].participant;
      if (participant) {
        if (!participant.buckets) {
          return false;
        }
        if (!Array.isArray(participant.buckets)) {
          return false;
        }
        if (!participant.buckets.length) {
          return true;
        }

        const device = participant.buckets[0].device;
        if (device) {
          return true;
        }
      }
    }
  }

  return false;
};

export const isAggregatedByDevice = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documents: any,
): documents is AggregatedByDevice['buckets'] => {
  if (!Array.isArray(documents)) {
    documents = [documents];
  }

  if (!documents.length || documents[0].key?.device) {
    return true;
  }

  return false;
};

const transformResponse = (response: string) => {
  const { data, ...props }: ResponseProps<StreamResponseFormat> =
    JSON.parse(response);

  if (!data || !isHits(data[0])) {
    return {
      data,
      ...props,
    };
  }

  return {
    data: [
      data[0].map(({ _index, _id, _source }) => ({
        _index,
        _id,
        ..._source,
      })),
      data[1],
    ],
    ...props,
  };
};

/**
 * Get aggregated result of streams
 * @param object
 * @property community_id - Not required. If it's not set, it will be set to loggined user's community id.
 * @property participant_id - Not required. If it's not set, this function returns community stream data. If it's -1, it will be set to loggined user's participant id.
 * @property range - Not required. If it's not set, this function returns exact range of startDate and endDate of stream data.
 * @property interval - Not required. If it's not set, this function returns device based aggregated stream data, otherwise it returns time based aggregated stream data.
 * @property startDate - Not required. If it's not set, it will be set to current time.
 * @property endDate - Not required. If it's not set, it will be set to current time.
 * @returns 2 length of array which has array of streams as the first element and count of array as the second element.
 */
export const getStreamsAggregated = async ({
  community_id,
  participant_id,
  range,
  interval,
  groupBy,
  time_zone,
  startDate = new Date(),
  endDate = new Date(),
  deviceId = 0,
}: AggregatedRequestProps & StreamReqRangeProps) => {
  community_id = community_id || _global.user?.participant?.community_id;

  let url = `community/${community_id}`;
  if (deviceId !== 0 && deviceId) url = url + `/device/${deviceId}`;

  if (participant_id) {
    url += `/participant/${participant_id}`;
  }

  url += '/stream';

  // const interval =
  //   range === TimeRange.Year ? '1M' : range === TimeRange.Today ? '15m' : '1d';

  url += prepareRequestParams({
    range,
    startDate: startDate.toUTCString(),
    endDate: endDate.toUTCString(),
    interval,
    groupBy,
    time_zone: time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return await requestAPI.get<
    ResponseProps<
      [
        (
          | AggregatedByTimeline<GroupByParticipant>['buckets']
          | AggregatedByTimeline<GroupByDevice>['buckets']
          | AggregatedByTimeline<GroupByParticipantAndDevice>['buckets']
          | AggregatedByDevice['buckets']
        ),
        number,
      ]
    >
  >(url, {
    transformResponse,
  });
};

export const getStreamHits = async ({
  community_id,
  participant_id,
  device_id,
  range,
  startDate,
  endDate,
  ...data
}: HitsRequestProps &
  StreamReqRangeProps &
  PaginationProps<StreamBasicInfo>) => {
  community_id = community_id || _global.user?.participant?.community_id;
  participant_id = participant_id || _global.user?.participant?.id;
  let url = `community/${community_id}/participant/${participant_id}/device/${device_id}/stream`;

  const { search, page, perPage, sort, sortField } = data;

  url += prepareRequestParams({
    search,
    page,
    perPage,
    sort,
    sortField,
    range,
    startDate: startDate?.toUTCString(),
    endDate: endDate?.toUTCString(),
  });

  return await requestAPI.get<ResponseProps<[StreamInfo[], number]>>(url, {
    transformResponse,
  });
};

export const addStream = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  device_id: string | number | null | undefined,
  {
    tag,
    type,
    ...data
  }: Partial<Pick<StreamInfo, 'unit' | 'tag' | 'type'>> & {
    value?: number;
  },
) => {
  let url = `/community/${community_id}/participant/${participant_id}/device/${device_id}/stream`;

  url += prepareRequestParams({
    tag,
    type,
  });

  return (await requestAPI.post)<ResponseProps<StreamResponseFormat>>(
    url,
    data,
  );
};

export const updateStream = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  device_id: string | number | null | undefined,
  {
    _index: index,
    _id: id,
    ...data
  }: Partial<Pick<StreamInfo, 'type' | 'tag' | 'value' | 'unit'>> &
    Pick<StreamInfo, '_index' | '_id'>,
) => {
  let url = `/community/${community_id}/participant/${participant_id}/device/${device_id}/stream`;

  url += prepareRequestParams({
    index,
    id,
  });

  return (await requestAPI.patch)<ResponseProps<StreamResponseFormat>>(
    url,
    data,
  );
};

export const deleteStreams = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  device_id: string | number | null | undefined,
  data: Pick<StreamInfo, '_index' | '_id'>[],
) => {
  let url = `/community/${community_id}/participant/${participant_id}/device/${device_id}/stream/multiple`;

  url += prepareRequestParams({
    documents: data,
  });

  return (await requestAPI.delete)<ResponseProps<StreamResponseFormat>>(url);
};

export const getStreamValue: (stream: StreamValue) => StreamData = (stream) => {
  const keys = Object.keys(stream);
  const values = Object.values(stream);
  if (!keys.length) {
    return {
      unit: getStringByLanguage('KWH'),
      total: 0,
      shared: 0,
      totalMembers: {},
      sharedMembers: {},
    };
  }

  return {
    unit: keys[0],
    ...values[0],
  };
};

export const mergeAndAggregateObjects = (
  obj1: {
    [id: string]: {
      value: number;
      unit: string;
    };
  },
  obj2: {
    [id: string]: {
      value: number;
      unit: string;
    };
  },
) => {
  const result: {
    [id: string]: {
      value: number;
      unit: string;
    };
  } = {};
  {
    const keys = Object.keys(obj1);
    const values = Object.values(obj1);
    keys.forEach((id, i) => {
      const { value, unit } = values[i];
      if (!result[id]) {
        result[id] = { value, unit };
      } else {
        result[id].value += value;
      }
    });
  }
  {
    const keys = Object.keys(obj2);
    const values = Object.values(obj2);
    keys.forEach((id, i) => {
      const { value, unit } = values[i];
      if (!result[id]) {
        result[id] = { value, unit };
      } else {
        result[id].value += value;
      }
    });
  }

  return result;
};
