import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  TimeScale,
  ChartOptions,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import * as DateFNS from 'date-fns';
import cn from 'classnames';
import { BsInfoCircle } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import { createRoot, Root } from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';

import SubSidebar from '../../../../components/SubSidebar/SubSidebar';
import { useLoadingBars } from '../../../../components/LoadingBar/LoadingBar';
import ChartTooltip from '../../../../components/ChartTooltip/ChartTooltip';
import {
  ChartDataObject,
  ChartDataProps,
  getChartLists,
  NavbarComponent,
} from '../../../Energy/Energy.helper';

import {
  colorDict,
  getDate,
  getStringByLanguage,
  prepareLabels,
  value2FlexibleUnit,
} from '../../../../utils/utils';

import { useAppDispatch, useAppSelector } from '../../../../services/hooks';
import {
  getColors,
  getPersonalView,
} from '../../../../services/reducers/sharedReducer';
import {
  getSelectedDate,
  getSelectedRange,
  setSelectedDate,
  setSelectedRange,
} from '../../../../services/reducers/sharedReducer';
import { getLoginedUserInfo } from '../../../../services/reducers/userReducer';

import {
  getStreamsAggregated,
  isGroupedByParticipant,
  StreamTag,
  TimeRange,
} from '../../../../apis/streams';
import {
  getParticipants,
  ParticipantDictionaryById,
  PersonType,
} from '../../../../apis/participants';
import { addUserPageLog, UserRole } from '../../../../apis/users';

import { ReactComponent as ProductionSVG } from '../../../../assets/images/Production.svg';
import { ReactComponent as ConsumptionSVG } from '../../../../assets/images/Consumption.svg';
import { ReactComponent as ExportSVG } from '../../../../assets/images/export.svg';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

ChartJS.defaults.borderColor = 'transparent';
ChartJS.defaults.elements.bar.borderRadius = 50;
ChartJS.defaults.elements.bar.borderSkipped = false;

const Energy: FC = () => {
  const { community_id } = useParams();
  const navigate = useNavigate();

  if (!community_id || isNaN(parseInt(community_id))) {
    navigate('/manage/communities');
    return <></>;
  }

  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const userInfo = useAppSelector(getLoginedUserInfo);
  const personalView = useAppSelector(getPersonalView);

  const [xAxisLabels, setXAxisLabels] = useState<number[]>([]);

  const dispatch = useAppDispatch();
  const selectedRange = useAppSelector(getSelectedRange);
  const date = useAppSelector(getSelectedDate);
  const selectedDate = new Date(date);

  const [period, setPeriod] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);
  const [selectedTag, setSelectedTag] = useState(
    userInfo?.participant?.type === PersonType.Producer
      ? StreamTag.Production
      : StreamTag.Consumption,
  );

  const [aggregated, setAggregated] = useState({
    total: { value: 0, unit: 'kWh' },
    shared: { value: 0, unit: 'kWh' },
    individual: { value: 0, unit: 'kWh' },
  });
  const [chartData, setChartData] = useState<ChartData<
    'bar',
    ChartDataObject[],
    number
  > | null>(null);
  const [chartDataLists, setChartDataLists] = useState<ChartDataProps>({
    unit: getStringByLanguage('KWH'),
    Production: { Total: [], Shared: [], Individual: [] },
    Consumption: { Total: [], Shared: [], Individual: [] },
  });

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>();

  const [tooltipRoot, setTooltipRoot] = useState<Root>();
  const [participantDict, setParticipantDict] =
    useState<ParticipantDictionaryById>({});

  const colors = useAppSelector(getColors);

  ChartJS.defaults.color = `rgb(${colors.textColor})`;
  ChartJS.defaults.borderColor = `rgb(${colors.textColor})`;

  const init = async () => {
    if (userInfo?.role === UserRole.Community_Manager) {
      const {
        data: { data },
      } = await getParticipants({ withDeleted: true });
      if (!data) {
        return;
      }

      const dict: ParticipantDictionaryById = {};
      data[0].forEach((participant) => (dict[participant.id] = participant));
      setParticipantDict(dict);
      return;
    }
  };

  useEffect(() => {
    init();
    addUserPageLog('Energy');
  }, []);

  useEffect(() => {
    let currentRoot = tooltipRoot;
    if (!currentRoot) {
      const tooltipContainer = document.getElementById('kut-chart-tooltip');
      if (!tooltipContainer) {
        return;
      }
      const _tooltipRoot = createRoot(tooltipContainer);
      currentRoot = _tooltipRoot;
      setTooltipRoot(_tooltipRoot);
    }

    setChartOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            useBorderRadius: true,
            borderRadius: 5,
            textAlign: 'right',
          },
        },
        title: {
          display: true,
          text:
            (selectedTag === StreamTag.Consumption
              ? getStringByLanguage('MY_CONSUMPTION')
              : getStringByLanguage('MY_PRODUCTION')) +
            ' (' +
            chartDataLists.unit +
            ')',
        },
        tooltip: {
          // Disable the on-canvas tooltip
          // enabled: true,
          enabled: false,

          external: (context) => {
            if (!currentRoot) {
              return;
            }
            currentRoot.render(
              <ChartTooltip
                dict={
                  userInfo?.role === UserRole.Community_Manager
                    ? participantDict
                    : {}
                }
                context={context}
                onlyPositive
              />,
            );
          },
        },
      },
      scales: {
        x: {
          type: 'time',
          grid: {
            display: false,
          },
          time: {
            unit: 'hour',
            displayFormats: {
              hour: 'H:mm',
            },
          },
          ticks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            callback: (value, index) => {
              switch (selectedRange) {
                case TimeRange.Today:
                  if (index % 4 === 0) {
                    return DateFNS.format(
                      new Date(value),
                      index === 0 ? 'H:mm' : 'k:mm',
                    );
                  }
                  return '';
                case TimeRange.Week:
                  return DateFNS.format(new Date(value), 'E');
                case TimeRange.Month:
                  return DateFNS.format(new Date(value), 'd');
                case TimeRange.Year:
                  return DateFNS.format(new Date(value), 'LLL');
              }
            },
            sampleSize: 1,
            autoSkip: false,
            source: 'labels',
          },
          stacked: true,
          alignToPixels: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
          grid: {
            drawTicks: false,
          },
          ticks: {
            callback: (value) => {
              if (typeof value === 'string') {
                return value;
              }
              return value < 0 ? -value : value;
            },
          },
          border: {
            display: false,
          },
        },
      },
    });
  }, [chartData, participantDict]);

  useEffect(() => {
    const data =
      selectedTag === StreamTag.Consumption
        ? chartDataLists.Consumption
        : chartDataLists.Production;

    const total = value2FlexibleUnit({
      value: data.Total.reduce((pv, cv) => pv + cv.value, 0),
      unit: chartDataLists.unit,
    });

    const shared = value2FlexibleUnit({
      value: data.Shared.reduce((pv, cv) => pv + cv.value, 0),
      unit: chartDataLists.unit,
    });

    const individual = value2FlexibleUnit({
      value: chartDataLists.Consumption.Individual.reduce(
        (pv, cv) => pv + cv.value,
        0,
      ),
      unit: chartDataLists.unit,
    });

    setAggregated({
      total,
      shared,
      individual,
    });

    const chart: ChartData<'bar', ChartDataObject[], number> = {
      labels: xAxisLabels,
      datasets: [
        {
          label:
            selectedTag === StreamTag.Consumption
              ? getStringByLanguage('CONSUMPTION')
              : getStringByLanguage('SHAREABLE_PRODUCTION'),
          data: data.Total,
          stack: 'Total',
          backgroundColor:
            selectedTag === StreamTag.Consumption
              ? colorDict['color-primary']
              : colorDict['color-warning'],
          order: 3,
          maxBarThickness: 12,
          parsing: {
            xAxisKey: 'timestamp',
            yAxisKey: 'value',
          },
        },
        {
          label:
            selectedTag === StreamTag.Consumption
              ? getStringByLanguage('COLLECTIVE_SELF_CONSUMPTION')
              : getStringByLanguage('SHARED_PRODUCTION'),
          data: data.Shared,
          stack: 'Element',
          backgroundColor: colorDict['color-success'],
          order: 2,
          maxBarThickness: 12,
          parsing: {
            xAxisKey: 'timestamp',
            yAxisKey: 'value',
          },
        },
        ...(userInfo?.participant?.type === PersonType.Prosumer &&
        chartDataLists.Consumption.Individual.length > 0
          ? [
              {
                label: getStringByLanguage('INDIVIDUAL_SELF_CONSUMPTION'),
                data: chartDataLists.Consumption.Individual,
                stack: 'Element',
                backgroundColor: colorDict['color-success-dark'],
                order: 1,
                maxBarThickness: 12,
                parsing: {
                  xAxisKey: 'timestamp',
                  yAxisKey: 'value',
                },
              },
            ]
          : []),
      ],
    };

    setChartData(chart);
  }, [chartDataLists, selectedTag]);

  useEffect(() => {
    if (!period) {
      return;
    }

    const xAxis = prepareLabels(selectedRange, selectedDate);
    setXAxisLabels(xAxis);
    showLoadingBar();

    getStreamsAggregated({
      community_id: Number(community_id),
      interval:
        selectedRange === TimeRange.Year
          ? '1M'
          : selectedRange === TimeRange.Today
          ? '15m'
          : '1d',
      groupBy: 'participant',
      ...period,
      ...((userInfo?.role === UserRole.Participant || personalView) && {
        participant_id: userInfo?.participant?.id,
      }),
    })
      .then(({ data: { data: streamData } }) => {
        if (!streamData) {
          throw new Error(getStringByLanguage('REQUEST_ERROR'));
        }

        const [streams] = streamData;

        if (isGroupedByParticipant(streams)) {
          const chartLists = getChartLists(streams);
          setChartDataLists(chartLists);
        }
        hideLoadingBar();
      })
      .catch(() => {
        hideLoadingBar();
      });
  }, [period, personalView]);

  useEffect(() => {
    setPeriodRange(selectedRange, selectedDate);
  }, [selectedRange, date]);

  const switchTag = (tag: StreamTag) => {
    if (selectedTag !== tag) {
      setSelectedTag(tag);
    }
  };

  const setPeriodRange = (selectedRange: TimeRange, current = new Date()) => {
    switch (selectedRange) {
      case TimeRange.Today:
        setPeriod({
          startDate: DateFNS.startOfDay(current),
          endDate: DateFNS.endOfDay(current),
        });
        break;
      case TimeRange.Week:
        setPeriod({
          startDate: DateFNS.startOfWeek(current),
          endDate: DateFNS.endOfWeek(current),
        });
        break;
      case TimeRange.Month:
        setPeriod({
          startDate: DateFNS.startOfMonth(current),
          endDate: DateFNS.endOfMonth(current),
        });
        break;
      case TimeRange.Year:
        setPeriod({
          startDate: DateFNS.startOfYear(current),
          endDate: DateFNS.endOfYear(current),
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <SubSidebar communityId={community_id} />
      <div className={styles['kut-component-body']}>
        <div className={styles['kut-component-content']}>
          <div className={styles['energy-select-tag']}>
            <div>
              {(userInfo?.role === UserRole.Community_Manager ||
                userInfo?.participant?.type === PersonType.Consumer ||
                userInfo?.participant?.type === PersonType.Prosumer) && (
                <span
                  className={cn([
                    selectedTag === StreamTag.Consumption && styles['active'],
                    styles['energy-select-tag-consumption'],
                  ])}
                  onClick={() => switchTag(StreamTag.Consumption)}
                >
                  <ConsumptionSVG />
                  {getStringByLanguage('CONSUMPTION')}
                </span>
              )}
              {(userInfo?.role === UserRole.Community_Manager ||
                userInfo?.participant?.type === PersonType.Producer ||
                userInfo?.participant?.type === PersonType.Prosumer) && (
                <span
                  className={cn([
                    selectedTag === StreamTag.Production && styles['active'],
                    styles['energy-select-tag-production'],
                  ])}
                  onClick={() => switchTag(StreamTag.Production)}
                >
                  <ProductionSVG />
                  {getStringByLanguage('PRODUCTION')}
                </span>
              )}
            </div>
          </div>
          <div className={styles['energy-action-bars']}>
            <NavbarComponent
              className={styles['energy-navbar']}
              options={[
                {
                  value: TimeRange.Today,
                  label: getStringByLanguage('DAY'),
                },
                {
                  value: TimeRange.Week,
                  label: getStringByLanguage('WEEK'),
                },
                {
                  value: TimeRange.Month,
                  label: getStringByLanguage('MONTH'),
                },
                {
                  value: TimeRange.Year,
                  label: getStringByLanguage('YEAR'),
                },
              ]}
              useArrow={{
                onPrev: (value: TimeRange) => {
                  if (!period) {
                    return;
                  }
                  if (selectedRange !== TimeRange.Custom) {
                    return dispatch(
                      setSelectedDate(
                        getDate(value, selectedDate, -1).getTime(),
                      ),
                    );
                  }
                  setPeriod({
                    startDate: getDate(value, period.startDate, -1),
                    endDate: period.endDate,
                  });
                },
                onNext: (value: TimeRange) => {
                  if (!period) {
                    return;
                  }
                  if (selectedRange !== TimeRange.Custom) {
                    return dispatch(
                      setSelectedDate(
                        getDate(value, selectedDate, 1).getTime(),
                      ),
                    );
                  }
                  setPeriod({
                    startDate: period.startDate,
                    endDate: getDate(value, period.endDate, 1),
                  });
                },
                component: (
                  <div className={cn([styles['inside-navbar']])}>
                    <DatePicker
                      selected={period?.startDate}
                      dateFormat={'dd/MM/yyyy'}
                      onChange={(date) =>
                        date &&
                        period &&
                        (selectedRange !== TimeRange.Custom
                          ? dispatch(setSelectedDate(date.getTime()))
                          : setPeriod({
                              startDate: date,
                              endDate: period.endDate,
                            }))
                      }
                    />
                    <span> - </span>
                    <DatePicker
                      selected={period?.endDate}
                      dateFormat={'dd/MM/yyyy'}
                      onChange={(date) =>
                        date &&
                        period &&
                        (selectedRange !== TimeRange.Custom
                          ? dispatch(setSelectedDate(date.getTime()))
                          : setPeriod({
                              startDate: period.startDate,
                              endDate: date,
                            }))
                      }
                    />
                  </div>
                ),
              }}
              onChange={(value: TimeRange) => {
                dispatch(setSelectedRange(value));
              }}
              value={selectedRange}
            />
            {userInfo?.role === UserRole.Community_Manager && (
              <div className={styles['energy-export']}>
                <ExportSVG />
                <span>{getStringByLanguage('EXPORT_DATA')}</span>
              </div>
            )}
          </div>
          <div className={styles['energy-result']}>
            <div className={styles['energy-total']}>
              <div className={styles['energy-result-header']}>
                <span>
                  {selectedTag === StreamTag.Consumption
                    ? getStringByLanguage('CONSUMPTION')
                    : getStringByLanguage('SHAREABLE_PRODUCTION')}
                </span>
                <BsInfoCircle
                  className={cn(['kut-tooltip-item'])}
                  data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    selectedTag === StreamTag.Consumption ? (
                      <div className={styles['energy-tooltip-item']}>
                        {getStringByLanguage([
                          'THE_AMOUNT_OF_ENERGY_THAT_YOU_TAKE_FROM_THE_GRID',
                          'IT_IS_THE_OFFTAKE_CONSUMPTION',
                        ])}
                      </div>
                    ) : (
                      <div className={styles['energy-tooltip-item']}>
                        {getStringByLanguage([
                          'THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_YOU_INJECT_INTO_THE_GRID_AND_MAKE_AVAILABLE_TO_THE_OTHER_MEMBERS',
                        ])}
                      </div>
                    ),
                  )}
                />
              </div>
              <div className={styles['energy-result-value']}>
                <span>{aggregated.total.value}</span>
                <span>{aggregated.total.unit}</span>
              </div>
            </div>
            <div className={styles['energy-shared']}>
              <div className={styles['energy-result-header']}>
                <span>
                  {selectedTag === StreamTag.Consumption
                    ? getStringByLanguage('COLLECTIVE_SELF_CONSUMPTION')
                    : getStringByLanguage('SHARED_PRODUCTION')}
                </span>
                <BsInfoCircle
                  className={cn(['kut-tooltip-item'])}
                  data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    selectedTag === StreamTag.Consumption ? (
                      <div className={styles['energy-tooltip-item']}>
                        {getStringByLanguage([
                          'THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_ENERGY_SHARING',
                        ])}
                      </div>
                    ) : (
                      <div className={styles['energy-tooltip-item']}>
                        {getStringByLanguage([
                          'THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED',
                        ])}
                      </div>
                    ),
                  )}
                />
              </div>
              <div className={styles['energy-result-value']}>
                <span>{aggregated.shared.value}</span>
                <span>{aggregated.shared.unit}</span>
              </div>
            </div>
            {(userInfo?.role === UserRole.Community_Manager ||
              userInfo?.participant?.type === PersonType.Prosumer) &&
              chartDataLists.Consumption.Individual.length > 0 && (
                <div className={styles['energy-individual']}>
                  <div className={styles['energy-result-header']}>
                    <span>
                      {getStringByLanguage('INDIVIDUAL_SELF_CONSUMPTION')}
                    </span>
                    <BsInfoCircle
                      className={cn(['kut-tooltip-item'])}
                      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        selectedTag === StreamTag.Consumption ? (
                          <div className={styles['energy-tooltip-item']}>
                            {getStringByLanguage([
                              'THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_IS_SELF_CONSUMED_AND_NOT_INJECTED_INTO_THE_GRID',
                              'IF_YOU_SUM_THE_CONSUMPTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_CONSUMPTION',
                            ])}
                          </div>
                        ) : (
                          <div className={styles['energy-tooltip-item']}>
                            {getStringByLanguage([
                              'THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_IS_SELF_CONSUMED_AND_NOT_INJECTED_INTO_THE_GRID',
                              'IF_YOU_SUM_THE_SHAREABLE_PRODUCTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_PRODUCTION',
                            ])}
                          </div>
                        ),
                      )}
                    />
                  </div>
                  <div className={styles['energy-result-value']}>
                    <span>{aggregated.individual.value}</span>
                    <span>{aggregated.individual.unit}</span>
                  </div>
                </div>
              )}
          </div>
          <div className={styles['energy-chart']}>
            {chartData && (
              <>
                <Bar options={chartOptions} data={chartData} />
                <div id="kut-chart-tooltip" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Energy;
